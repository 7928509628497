<template>
  <v-app>
    <BottonTopDown />
    <Drawer v-if="isSigned === 'signed'" />
    <v-main>
      <router-view></router-view>
      <v-card elevation="0" height="100" class="d-none d-md-block">
        <v-row style="font-size: 14px">
          <v-col class="h1 text-bottom">
            <!-- <p>Versão: 0.01 - 08/06/2023</p> -->
            <!-- <p>
              <v-img v-if="true" alt="SADT" class="ma-auto" src="./assets/logo.png" width="80" />
            </p> -->
            <p>Versão: 1.3</p>
            <p>&copy; 2024 - L N O TECNOLOGIA. Todos os direitos reservados.</p>
          </v-col>
        </v-row>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getCookies } from './service/utilsFunc'
import './assets/css/styles.css';

export default {
  name: 'App',
  components: {
    Drawer: () => import('./components/drawer/drawer.vue'),
    BottonTopDown: () => import('./components/bottom_top_down.vue'),
  },
  data: () => ({
    initiallyOpen: [''],
    btn: true,
    group: null,
    drawer: true,
    snackbar: false,
    items: [
      { title: 'Home', icon: 'mdi-home-city' },
      { title: 'My Account', icon: 'mdi-account' },
      { title: 'Users', icon: 'mdi-account-group-outline' },
    ],
    mini: true
  }),
  methods: {
    ...mapActions(['updateSystemColor']),
    fechaLoading() {
      this.snackbar = false
      this.$store.dispatch('btnLoading', false)
    },
    updatePrimaryColor() {
      const color = localStorage.getItem('sadt@primary-color')
      if (color) {
        const themes = {
          themes: this.$vuetify.theme.themes,
          color
        }
        this.updateSystemColor(themes)
      }

    }
  },

  computed: {
    ...mapGetters(['isSigned', 'tokenAccess']),
  },
  mounted() {
    const cookies = getCookies();
    if (cookies.accessToken !== undefined) {
      this.$store.commit('updateUserData')
      this.$store.dispatch('permissoes', 'contas/permissoes/')
      // console.log('token que está vindo--->', cookies.accessToken)
    }
    this.updatePrimaryColor()
  },
};

</script>
<style>
@font-face {
  font-family: 'museosans';
  src: url('~@/assets/css/fonts/MUSEOSANS.OTF');
  font-style: normal;
  font-weight: 100;

}

html,
body {
  font-family: 'museosans';
}

#app {
  font-family: 'museosans';
}

.azul {
  background-color: '#0aabbe' !important
}

.v-application {
  background-color: #fff !important;
}

.theme--dark .v-application {
  background: #47A4AD;
  color: #4d4d4d;
}

.v-application .primary {
  background-color: #fff !important;
  border-color: #1976d2 !important;
}

.borda-azul {
  border-color: blue !important;
}

.verde-1 {
  color: #02b2b8;
}
</style>
