import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { router } from "./routes";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "./vuex";
import VueTheMask from "vue-the-mask";
import money from "v-money";
import UtilsService from "./service/utilsService";
import * as VueGoogleMaps from "vue2-google-maps";
import VueCookies from "vue-cookies";
import "./plugins/vuetify-money.js";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueSignature from "vue-signature-pad";
import { ErrorHandler } from "./service/errorHandler";
import VueHtml2pdf from 'vue-html2pdf'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import VueQuillEditor from 'vue-quill-editor'
Vue.use(VueQuillEditor, {
	placeholder: 'Conteúdo aqui...',
	theme: 'snow',
})

Vue.config.productionTip = false;

Vue.use(VueTheMask);
Vue.use(VueCookies);
Vue.use(VueHtml2pdf);
Vue.use(VueSignature);
Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(money, { precision: 4 });
Vue.use(Toast, { position: "bottom-right" });
Vue.use(ErrorHandler);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAfrtV0BQ5k2GUJCgMaTsneZJ7XpmdRZeI",
    libraries: "places",
  },
});

Vue.filter("formatDatabr", (val) => UtilsService.formatDatabr(val));
Vue.filter("formatData", (val) => UtilsService.formatData(val));
Vue.filter("formatDataXML", (val) => UtilsService.formatDataXML(val));
Vue.filter("formatCurrency", (val) => UtilsService.formatCurrency(val));
Vue.filter("formatCPF", (val) => UtilsService.formatCPF(val));
Vue.filter("protectCPF", (val) => UtilsService.protectCPF(val));
Vue.filter("weekDay", (val) => UtilsService.getWeekDay(val));
Vue.filter("formatReal", (val) => UtilsService.formatReal(val));
Vue.filter("formatTimestamp", (val) => UtilsService.formatTimestamp(val));
Vue.filter("formatTimestamp_date", (val) =>
  UtilsService.formatTimestamp_date(val)
);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
