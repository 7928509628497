import axios from "axios";
import UtilsService from "../service/utilsService";
import { getCookies } from "../service/utilsFunc";
import { router } from "../routes";
import Vue from "vue";

const api = (() => {
  const cookies = getCookies();

  return axios.create({
    baseURL: UtilsService.urlApi(),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookies.accessToken}`,
    },
  });
})();

api.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response !== undefined && error.response.status === 401) {
      const cookies = Vue.prototype.$cookies;
      if (cookies.get("accessToken")) {
        cookies.remove("accessToken");
        router.push("/login");
      }
    }
    return Promise.reject(error);
  }
);
export default api;
