export default class UtilsFunc {
  static formatData(val) {
    return new Date(val).toLocaleDateString();
  }
  static formatDatabr(val) {
    if (!val) return "";
    const [year, month, day] = val.split("-");
    return new Date(year, month - 1, day).toLocaleDateString();
  }
  static formatTimestamp(val) {
    return new Date(val * 1000).toLocaleString();
  }
  static formatTimestamp_date(val) {
    return new Date(val * 1000).toLocaleDateString();
  }
  static protectCPF(val) {
    var c = val.split("");
    var protect = c.map((char, i) => {
      // marcarar apenas indices maior que a 4ª pos e menor que a 11ª pos, a partir de um CPF Formatado com o filtro Vue formatCPF
      return i > 3 && i < 11 ? "*" : char;
    });
    return protect.join("");
  }

  static formatCPF(val) {
    if (val) {
      var formattedCPF = val.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        "$1.$2.$3-$4"
      );
      return formattedCPF;
    }
    return "";
  }

  static getWeekDay(val) {
    return new Date(val).toLocaleDateString("pt-br", { weekday: "long" });
  }
  static formatReal(val) {
    return new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL", }).format(val);
  }
  static urlApi() {
    //return 'https://gssma.com.br/apps/';
    //return process.env.NODE_ENV === 'production' ? 'https://gssma.com.br/apps' : 'http://localhost:8000';
    return process.env.VUE_APP_API_URL;
  }
  static urlApp() {
    //return 'http://192.168.0.229:8080/'                                                                   // esse ip é do teu wifi? não esse é do servidor
    return process.env.VUE_APP_APP_URL;
  }
}
// '' mostra o terminal de novo
