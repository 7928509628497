export const ErrorHandler = () => {};
ErrorHandler.install = function (Vue) {
  Vue.prototype.$errorHandler = function (error) {
    if (error.response?.data) {
      const errors = error.response.data;
      if (Array.isArray(errors)) {
        return errors.forEach((error) => this.$toast.error(error));
      }
      console.log(errors);
      return this.$toast.error(errors);
    } else {
      return this.$toast.error(error.message);
    }
  };
};
